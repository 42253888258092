<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.82774 8.67808C5.96636 8.24087 6.17144 7.83332 6.43159 7.46743L12.5327 13.5679C11.8168 14.0762 10.9433 14.3751 9.99957 14.3751C7.5842 14.3751 5.62457 12.4162 5.62457 10.0001C5.62457 9.53922 5.69672 9.09512 5.82774 8.67808Z"
      fill="var(--components-main100)" />
    <path
      d="M7.46267 6.43563L13.5637 12.5374C14.0745 11.8218 14.3746 10.9459 14.3746 10.0001C14.3746 7.58386 12.4149 5.62505 9.99957 5.62505C9.05393 5.62505 8.17855 5.92531 7.46267 6.43563Z"
      fill="var(--components-main100)" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M9.99957 19.7223C15.3696 19.7223 19.7218 15.3695 19.7218 10.0001C19.7218 4.63064 15.3696 0.277832 9.99957 0.277832C4.62956 0.277832 0.277344 4.63064 0.277344 10.0001C0.277344 15.3695 4.62956 19.7223 9.99957 19.7223ZM15.8329 10.0001C15.8329 13.2217 13.2219 15.8334 9.99957 15.8334C6.77718 15.8334 4.16623 13.2217 4.16623 10.0001C4.16623 6.77838 6.77718 4.16672 9.99957 4.16672C13.2219 4.16672 15.8329 6.77838 15.8329 10.0001Z"
      fill="var(--components-main100)" />
  </svg>
</template>