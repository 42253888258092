import { http } from '../api';
import { Filters, PaginateResponse } from '../model';
import { Company } from '../model/Company';

const controller = 'company';

export async function getPaginate(filters?: Filters): Promise<PaginateResponse<Company>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }
  const { data } = await http.get<PaginateResponse<Company> | any>(`${controller}`, {
    params,
  });

  return {
    page: data.page,
    total: data.total ? data.total : data.length,
    data: data.data ? data.data : data,
    limit: data.limit ? data.limit : '',
    totalPages: data.totalPages ? data.totalPages : ''
  };
}

export async function getBy(id: string): Promise<Company> {
  const { data } = await http.get<Company>(`${controller}/${id}`);
  return data;
}

export async function create(payload: Company): Promise<Company> {
  const { data } = await http.post<Company>(`${controller}/`, payload);
  return data;
}

export async function update(id: string, payload: Company): Promise<Company> {
  const { data } = await http.put<Company>(`${controller}/${id}`, { ...payload, id });
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  return status === 204;
}
