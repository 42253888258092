<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3653 4.84161V6.99992H10.6387C8.76036 6.99992 7.81538 7.9449 7.81538 9.82323V20.9999H4.84039C3.17206 20.9999 2.33203 20.1599 2.33203 18.4916V4.84161C2.33203 3.17328 3.17206 2.33325 4.84039 2.33325H9.85705C11.5254 2.33325 12.3653 3.17328 12.3653 4.84161Z"
      :fill="'var(--components-main100)'"
    />
    <path
      opacity="0.4"
      d="M20.2647 9.82331V22.8434C20.2647 24.7217 19.3314 25.6667 17.4531 25.6667H10.6397C8.76139 25.6667 7.81641 24.7217 7.81641 22.8434V9.82331C7.81641 7.94498 8.76139 7 10.6397 7H17.4531C19.3314 7 20.2647 7.94498 20.2647 9.82331Z"
      :fill="'var(--components-main100)'"
    />
    <path
      d="M25.6661 4.84161V18.4916C25.6661 20.1599 24.8261 20.9999 23.1578 20.9999H20.2644V9.82323C20.2644 7.9449 19.3311 6.99992 17.4528 6.99992H15.6328V4.84161C15.6328 3.17328 16.4728 2.33325 18.1411 2.33325H23.1578C24.8261 2.33325 25.6661 3.17328 25.6661 4.84161Z"
      :fill="'var(--components-main100)'"
    />
    <path
      d="M16.3346 13.7083H11.668C11.1896 13.7083 10.793 13.3116 10.793 12.8333C10.793 12.3549 11.1896 11.9583 11.668 11.9583H16.3346C16.813 11.9583 17.2096 12.3549 17.2096 12.8333C17.2096 13.3116 16.813 13.7083 16.3346 13.7083Z"
      :fill="'var(--components-main100)'"
    />
    <path
      d="M16.3346 17.2083H11.668C11.1896 17.2083 10.793 16.8116 10.793 16.3333C10.793 15.8549 11.1896 15.4583 11.668 15.4583H16.3346C16.813 15.4583 17.2096 15.8549 17.2096 16.3333C17.2096 16.8116 16.813 17.2083 16.3346 17.2083Z"
      :fill="'var(--components-main100)'"
    />
    <path
      d="M14.875 22.1667V25.6667H13.125V22.1667C13.125 21.6884 13.5217 21.2917 14 21.2917C14.4783 21.2917 14.875 21.6884 14.875 22.1667Z"
      :fill="'var(--components-main100)'"
    />
  </svg>
</template>