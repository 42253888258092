<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9545 6.07837L19.5994 9.36837C19.8211 9.82337 20.4161 10.255 20.9178 10.3484L23.8928 10.8384C25.7945 11.1534 26.2378 12.53 24.8728 13.9067L22.5511 16.2284C22.1661 16.6134 21.9445 17.3717 22.0728 17.92L22.7378 20.79C23.2628 23.0534 22.0495 23.94 20.0545 22.75L17.2661 21.0934C16.7644 20.79 15.9245 20.79 15.4228 21.0934L12.6344 22.75C10.6394 23.9284 9.42612 23.0534 9.95112 20.79L10.6161 17.92C10.7445 17.3834 10.5228 16.625 10.1378 16.2284L7.81613 13.9067C6.45113 12.5417 6.89446 11.165 8.79613 10.8384L11.7711 10.3484C12.2728 10.2667 12.8678 9.82337 13.0895 9.36837L14.7345 6.07837C15.6095 4.29337 17.0561 4.29337 17.9545 6.07837Z"
      :fill="'var(--components-main100)'"
    />
    <path
      opacity="0.4"
      d="M9.33203 6.70837H2.33203C1.8537 6.70837 1.45703 6.31171 1.45703 5.83337C1.45703 5.35504 1.8537 4.95837 2.33203 4.95837H9.33203C9.81036 4.95837 10.207 5.35504 10.207 5.83337C10.207 6.31171 9.81036 6.70837 9.33203 6.70837Z"
      :fill="'var(--components-main100)'"
    />
    <path
      opacity="0.4"
      d="M5.83203 23.0416H2.33203C1.8537 23.0416 1.45703 22.645 1.45703 22.1666C1.45703 21.6883 1.8537 21.2916 2.33203 21.2916H5.83203C6.31036 21.2916 6.70703 21.6883 6.70703 22.1666C6.70703 22.645 6.31036 23.0416 5.83203 23.0416Z"
      :fill="'var(--components-main100)'"
    />
    <path
      opacity="0.4"
      d="M3.4987 14.875H2.33203C1.8537 14.875 1.45703 14.4783 1.45703 14C1.45703 13.5217 1.8537 13.125 2.33203 13.125H3.4987C3.97703 13.125 4.3737 13.5217 4.3737 14C4.3737 14.4783 3.97703 14.875 3.4987 14.875Z"
      :fill="'var(--components-main100)'"
    />
  </svg>
</template>