<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 17.3334H1.5C1.15833 17.3334 0.875 17.05 0.875 16.7084C0.875 16.3667 1.15833 16.0834 1.5 16.0834H16.5C16.8417 16.0834 17.125 16.3667 17.125 16.7084C17.125 17.05 16.8417 17.3334 16.5 17.3334Z"
      fill="var(--components-main100)" />
    <path
      d="M14.85 1.90005C13.2333 0.283383 11.65 0.241716 9.99167 1.90005L8.98333 2.90838C8.9 2.99172 8.86667 3.12505 8.9 3.24172C9.53333 5.45005 11.3 7.21672 13.5083 7.85005C13.5417 7.85838 13.575 7.86672 13.6083 7.86672C13.7 7.86672 13.7833 7.83338 13.85 7.76672L14.85 6.75838C15.675 5.94172 16.075 5.15005 16.075 4.35005C16.0833 3.52505 15.6833 2.72505 14.85 1.90005Z"
      fill="var(--components-main100)" />
    <path
      d="M12.0083 8.60838C11.7667 8.49172 11.5333 8.37505 11.3083 8.24172C11.125 8.13338 10.95 8.01672 10.775 7.89172C10.6333 7.80005 10.4667 7.66672 10.3083 7.53338C10.2917 7.52505 10.2333 7.47505 10.1667 7.40838C9.89167 7.17505 9.58333 6.87505 9.30833 6.54172C9.28333 6.52505 9.24167 6.46672 9.18333 6.39172C9.1 6.29172 8.95833 6.12505 8.83333 5.93338C8.73333 5.80838 8.61667 5.62505 8.50833 5.44172C8.375 5.21672 8.25833 4.99172 8.14167 4.75838C8.12401 4.72055 8.10693 4.68292 8.09037 4.6455C7.96739 4.36773 7.60519 4.28653 7.39039 4.50132L2.61667 9.27505C2.50833 9.38338 2.40833 9.59172 2.38333 9.73338L1.93333 12.925C1.85 13.4917 2.00833 14.025 2.35833 14.3834C2.65833 14.675 3.075 14.8334 3.525 14.8334C3.625 14.8334 3.725 14.8251 3.825 14.8084L7.025 14.3584C7.175 14.3334 7.38333 14.2334 7.48333 14.125L12.2511 9.35729C12.4674 9.141 12.3858 8.76978 12.1045 8.64973C12.0728 8.63621 12.0408 8.62244 12.0083 8.60838Z"
      fill="var(--components-main100)" />
  </svg>
</template>