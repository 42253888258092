<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M16 2.66667C12.5066 2.66667 9.66663 5.50667 9.66663 9C9.66663 12.4267 12.3466 15.2 15.84 15.32C15.9466 15.3067 16.0533 15.3067 16.1333 15.32C16.16 15.32 16.1733 15.32 16.2 15.32C16.2133 15.32 16.2133 15.32 16.2266 15.32C19.64 15.2 22.32 12.4267 22.3333 9C22.3333 5.50667 19.4933 2.66667 16 2.66667Z"
      :fill="'var(--components-main100)'"
    />
    <path
      d="M22.7733 18.8667C19.0533 16.3867 12.9866 16.3867 9.23995 18.8667C7.54661 20 6.61328 21.5333 6.61328 23.1733C6.61328 24.8133 7.54661 26.3333 9.22661 27.4533C11.0933 28.7067 13.5466 29.3333 15.9999 29.3333C18.4533 29.3333 20.9066 28.7067 22.7733 27.4533C24.4533 26.32 25.3866 24.8 25.3866 23.1467C25.3733 21.5067 24.4533 19.9867 22.7733 18.8667Z"
      :fill="'var(--components-main100)'"
    />
  </svg>
</template>