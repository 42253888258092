<template>

    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2"
            d="M8.9974 0.666992C13.5997 0.666992 17.3307 4.39795 17.3307 9.00033C17.3307 13.6027 13.5997 17.3337 8.9974 17.3337C4.39502 17.3337 0.664062 13.6027 0.664062 9.00033C0.664062 4.39795 4.39502 0.666992 8.9974 0.666992Z"
            fill="#FF5C00" />
        <path
            d="M8.99707 7.54199C9.33874 7.54199 9.62207 7.82533 9.62207 8.16699V12.3337C9.62207 12.6753 9.33874 12.9587 8.99707 12.9587C8.6554 12.9587 8.37207 12.6753 8.37207 12.3337V8.16699C8.37207 7.82533 8.6554 7.54199 8.99707 7.54199Z"
            fill="#FF5C00" />
        <path
            d="M9.76406 5.9835C9.7224 6.0835 9.66406 6.17517 9.58906 6.2585C9.50573 6.3335 9.41406 6.39184 9.31406 6.4335C9.11406 6.51684 8.88073 6.51684 8.68073 6.4335C8.58073 6.39184 8.48906 6.3335 8.40573 6.2585C8.33073 6.17517 8.2724 6.0835 8.23073 5.9835C8.18906 5.8835 8.16406 5.77517 8.16406 5.66684C8.16406 5.5585 8.18906 5.45017 8.23073 5.35017C8.2724 5.24184 8.33073 5.1585 8.40573 5.07517C8.48906 5.00017 8.58073 4.94183 8.68073 4.90016C8.78073 4.8585 8.88906 4.8335 8.9974 4.8335C9.10573 4.8335 9.21406 4.8585 9.31406 4.90016C9.41406 4.94183 9.50573 5.00017 9.58906 5.07517C9.66406 5.1585 9.7224 5.24184 9.76406 5.35017C9.80573 5.45017 9.83073 5.5585 9.83073 5.66684C9.83073 5.77517 9.80573 5.8835 9.76406 5.9835Z"
            fill="#FF5C00" />
    </svg>


</template>