<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M13.9987 25.6667C20.442 25.6667 25.6654 20.4434 25.6654 14C25.6654 7.55672 20.442 2.33337 13.9987 2.33337C7.55538 2.33337 2.33203 7.55672 2.33203 14C2.33203 20.4434 7.55538 25.6667 13.9987 25.6667Z"
      :fill="'var(--components-main100)'"
    />
    <path
      d="M17.4072 12.3551C17.3283 12.1505 17.2005 11.9767 17.0391 11.8546C16.8777 11.7325 16.6896 11.6673 16.4972 11.6668H15.5923L16.2572 9.76524C16.3166 9.59063 16.339 9.40187 16.3225 9.21501C16.3059 9.02814 16.251 8.8487 16.1622 8.69194C16.0674 8.52954 15.9398 8.39676 15.7908 8.30524C15.6417 8.21371 15.4757 8.16623 15.3073 8.16696H13.1374C12.9323 8.16124 12.7306 8.22926 12.5598 8.36178C12.389 8.4943 12.2572 8.68491 12.1824 8.90777L10.5674 13.5743C10.5096 13.7485 10.4885 13.9365 10.5059 14.1222C10.5233 14.308 10.5787 14.4862 10.6674 14.6417C10.7623 14.8041 10.8898 14.9369 11.0389 15.0284C11.188 15.12 11.354 15.1674 11.5224 15.1667H12.9024L11.9974 19.0983C11.9672 19.2266 11.9756 19.363 12.0211 19.4851C12.0665 19.6071 12.1464 19.7073 12.2474 19.7691C12.3254 19.8127 12.4109 19.8346 12.4974 19.8332C12.5696 19.8332 12.641 19.815 12.7066 19.7797C12.7721 19.7445 12.8304 19.6931 12.8774 19.6291L17.2772 13.5801C17.3949 13.4105 17.4694 13.2059 17.4924 12.9893C17.5154 12.7728 17.4859 12.5531 17.4072 12.3551Z"
      :fill="'var(--components-main100)'"
    />
  </svg>
</template>
