<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.25 7V9.1C16.81 9.04 16.31 9.01 15.75 9V7C15.75 3.85 14.86 1.75 10.5 1.75C6.14 1.75 5.25 3.85 5.25 7V9C4.69 9.01 4.19 9.04 3.75 9.1V7C3.75 4.1 4.45 0.25 10.5 0.25C16.55 0.25 17.25 4.1 17.25 7Z"
      fill="#FF5C00" />
    <path
      d="M17.25 9.1C16.81 9.04 16.31 9.01 15.75 9H5.25C4.69 9.01 4.19 9.04 3.75 9.1C1.2 9.41 0.5 10.66 0.5 14V16C0.5 20 1.5 21 5.5 21H15.5C19.5 21 20.5 20 20.5 16V14C20.5 10.66 19.8 9.41 17.25 9.1ZM7.21 15.71C7.02 15.89 6.76 16 6.5 16C6.37 16 6.24 15.97 6.12 15.92C5.99 15.87 5.89 15.8 5.79 15.71C5.61 15.52 5.5 15.26 5.5 15C5.5 14.87 5.53 14.74 5.58 14.62C5.63 14.5 5.7 14.39 5.79 14.29C5.89 14.2 5.99 14.13 6.12 14.08C6.49 13.92 6.93 14.01 7.21 14.29C7.3 14.39 7.37 14.5 7.42 14.62C7.47 14.74 7.5 14.87 7.5 15C7.5 15.26 7.39 15.52 7.21 15.71ZM11.42 15.38C11.37 15.5 11.3 15.61 11.21 15.71C11.02 15.89 10.76 16 10.5 16C10.23 16 9.98 15.89 9.79 15.71C9.7 15.61 9.63 15.5 9.58 15.38C9.53 15.26 9.5 15.13 9.5 15C9.5 14.73 9.61 14.48 9.79 14.29C10.16 13.92 10.83 13.92 11.21 14.29C11.39 14.48 11.5 14.73 11.5 15C11.5 15.13 11.47 15.26 11.42 15.38ZM15.21 15.71C15.02 15.89 14.76 16 14.5 16C14.24 16 13.98 15.89 13.79 15.71C13.61 15.52 13.5 15.27 13.5 15C13.5 14.73 13.61 14.48 13.79 14.29C14.17 13.92 14.84 13.92 15.21 14.29C15.25 14.34 15.29 14.39 15.33 14.45C15.37 14.5 15.4 14.56 15.42 14.62C15.45 14.68 15.47 14.74 15.48 14.8C15.49 14.87 15.5 14.94 15.5 15C15.5 15.26 15.39 15.52 15.21 15.71Z"
      fill="#FF5C00" />
  </svg>

</template>