import { Filters, OptionSelect, PaginateRequest, PaginateResponse } from '@/core/model';
import { Company } from '@/core/model/Company';
import { getPaginate } from '@/core/service/CompanyService';
import { reactive } from 'vue';

const state = reactive<{ company?: Company[]; }>({
  company: undefined,
});

const actions = {
  async GetAll(forceRefresh = false) {
    if (!state.company || forceRefresh) {
      const { data } = await getPaginate();
      state.company = data;
    }
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters, refresh = false): Promise<PaginateResponse<Company>> {

    if (refresh || !state.company) {
      const response = await getPaginate({ ...paginate, ...filters });
      state.company = response.data;
      return response;
    }
    return {
      data: state.company || [],
      page: paginate.page,
      total: state.company?.length || 0,
      limit: paginate.limit,
      totalPages: Math.ceil((state.company?.length || 0) / (paginate.limit || 1)),
    };
  },
};

const getters = {};

export { state, actions, getters };
