<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5413 4.15337V2.33337C19.5413 1.85504 19.1446 1.45837 18.6663 1.45837C18.1879 1.45837 17.7913 1.85504 17.7913 2.33337V4.08337H10.2079V2.33337C10.2079 1.85504 9.81127 1.45837 9.33294 1.45837C8.85461 1.45837 8.45794 1.85504 8.45794 2.33337V4.15337C5.30794 4.44504 3.77962 6.32337 3.54628 9.1117C3.52295 9.45004 3.80295 9.73004 4.12962 9.73004H23.8696C24.2079 9.73004 24.4879 9.43837 24.4529 9.1117C24.2196 6.32337 22.6913 4.44504 19.5413 4.15337Z"
      :fill="'var(--components-main100)'"
    />
    <path
      opacity="0.4"
      d="M23.3333 11.48C23.975 11.48 24.5 12.005 24.5 12.6466V19.8333C24.5 23.3333 22.75 25.6667 18.6667 25.6667H9.33333C5.25 25.6667 3.5 23.3333 3.5 19.8333V12.6466C3.5 12.005 4.025 11.48 4.66667 11.48H23.3333Z"
      :fill="'var(--components-main100)'"
    />
    <path
      d="M9.91667 17.5C9.765 17.5 9.61333 17.465 9.47333 17.4067C9.33333 17.3484 9.20501 17.2667 9.08834 17.1617C8.98334 17.045 8.90165 16.9167 8.84332 16.7767C8.78498 16.6367 8.75 16.485 8.75 16.3333C8.75 16.1817 8.78498 16.03 8.84332 15.89C8.90165 15.75 8.98334 15.6217 9.08834 15.505C9.20501 15.4 9.33333 15.3183 9.47333 15.26C9.75333 15.1433 10.08 15.1433 10.36 15.26C10.5 15.3183 10.6283 15.4 10.745 15.505C10.85 15.6217 10.9317 15.75 10.99 15.89C11.0483 16.03 11.0833 16.1817 11.0833 16.3333C11.0833 16.485 11.0483 16.6367 10.99 16.7767C10.9317 16.9167 10.85 17.045 10.745 17.1617C10.6283 17.2667 10.5 17.3484 10.36 17.4067C10.22 17.465 10.0683 17.5 9.91667 17.5Z"
      :fill="'var(--components-main100)'"
    />
    <path
      d="M14.0007 17.5C13.849 17.5 13.6973 17.465 13.5573 17.4066C13.4173 17.3483 13.289 17.2666 13.1723 17.1616C12.9623 16.94 12.834 16.6366 12.834 16.3333C12.834 16.03 12.9623 15.7266 13.1723 15.505C13.289 15.4 13.4173 15.3183 13.5573 15.2599C13.8373 15.1316 14.164 15.1316 14.444 15.2599C14.584 15.3183 14.7123 15.4 14.829 15.505C15.039 15.7266 15.1673 16.03 15.1673 16.3333C15.1673 16.6366 15.039 16.94 14.829 17.1616C14.7123 17.2666 14.584 17.3483 14.444 17.4066C14.304 17.465 14.1523 17.5 14.0007 17.5Z"
      :fill="'var(--components-main100)'"
    />
    <path
      d="M9.91667 21.5834C9.765 21.5834 9.61333 21.5484 9.47333 21.4901C9.33333 21.4317 9.20501 21.35 9.08834 21.245C8.87834 21.0234 8.75 20.72 8.75 20.4167C8.75 20.1134 8.87834 19.8101 9.08834 19.5884C9.20501 19.4834 9.33333 19.4017 9.47333 19.3434C9.75333 19.2267 10.08 19.2267 10.36 19.3434C10.5 19.4017 10.6283 19.4834 10.745 19.5884C10.955 19.8101 11.0833 20.1134 11.0833 20.4167C11.0833 20.72 10.955 21.0234 10.745 21.245C10.6283 21.35 10.5 21.4317 10.36 21.4901C10.22 21.5484 10.0683 21.5834 9.91667 21.5834Z"
      :fill="'var(--components-main100)'"
    />
  </svg>
</template>