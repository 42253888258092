export function verifyPermission(permissionsALlow: Array<string>): boolean{
    const userPermisisons = getUserPermisisons()
    return permissionsALlow.some((value:any) =>  userPermisisons.includes(value))
}

export function getUserPermisisons(): Array<string> {
    const userString = localStorage.getItem('usuario');

    if (!userString) {
        return [];
    }

    let user;
    try {
        user = JSON.parse(userString);
    } catch (error) {
        console.error('Erro ao analisar JSON do usuário:', error);
        return [];
    }

    if (user && Array.isArray(user.roles)) {
        return user.roles.map((role: any) => role.description);
    }

    return [];
}
