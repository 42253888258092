
export const setThemeColors = (primaryColor = "#FF5C00", theme = "light") => {
    const primaryVariations = {
        c10: hexToRGBA(primaryColor, 0.1),
        c25: hexToRGBA(primaryColor, 0.25),
        c50: hexToRGBA(primaryColor, 0.5),
        c75: hexToRGBA(primaryColor, 0.75),
        c100: primaryColor,
    };

    const helpColors = {
        warning: { c10: "rgba(255, 170, 0, 0.1)", c100: "#FFAA00" },
        danger: { c10: "rgba(244, 56, 65, 0.1)", c100: "#F43841" },
        success: { c10: "rgba(0, 184, 141, 0.1)", c100: "#00B88D" },
        info: { c10: "rgba(0, 149, 255, 0.1)", c100: "#0095FF" },
    };

    const backgroundColors = theme === "light"
        ? { primary: "#FFFFFF", secondary: "#FAFAFA", surfacePrimary: "#FFFFFF", surfaceSecondary: "#FFFFFF" }
        : { primary: "#121212", secondary: "#121212", surfacePrimary: "#171717", surfaceSecondary: hexToRGBA(primaryColor, 0.1) };

    const componentsColors = theme === "light"
        ? {
            title: "#292E36",
            subtitle: "#697B8E",
            hover: "#F6F7F9",
            stroke: "#D6DBE1",
            icon: "#B2BBC7",
            white: "#FFFFFF",
            main100: primaryVariations.c100,
            main10: primaryVariations.c10,
        }
        : {
            title: "#FFFFFF",
            subtitle: "#D0D0D0",
            hover: hexToRGBA("#FFFFFF", 0.05),
            stroke: "#414141",
            icon: "#717171",
            white: primaryVariations.c100,
            main100: "#FFFFFF",
            main10: hexToRGBA("#FFFFFF", 0.1),
        };

    // Aplicando no CSS
    Object.entries(primaryVariations).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--primary-${key}`, value);
    });

    Object.entries(helpColors).forEach(([type, shades]) => {
        Object.entries(shades).forEach(([key, value]) => {
            document.documentElement.style.setProperty(`--${type}-${key}`, value);
        });
    });

    Object.entries(backgroundColors).forEach(([key, value]) => {
        console.log(key)
        document.documentElement.style.setProperty(`--background-${key}`, value);
    });

    Object.entries(componentsColors).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--components-${key}`, value);
    });

    // Definir um atributo no body para CSS Scoped
    document.documentElement.setAttribute("data-theme", theme);
};

// Função auxiliar para converter HEX para RGBA
const hexToRGBA = (hex, opacity) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 7) {
        r = parseInt(hex.substring(1, 3), 16);
        g = parseInt(hex.substring(3, 5), 16);
        b = parseInt(hex.substring(5, 7), 16);
    }
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

